import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const SonyCreETen = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: 'DugH-kel2yo',
			url: 'https://www.youtube.com/embed/DugH-kel2yo?si=A2KVVB-IGhXDO3Ah',
			title: 'Introducing the Sony Self-Fitting OTC Hearing Aids | hear.com',
			subtitle:
				'Meet the comfortable, easy-to-use OTC solution from Sony, helping you hear what matters most.'
		},
		{
			id: 'U_q8wgzWzT4',
			url: 'https://www.youtube.com/embed/U_q8wgzWzT4?si=KKEShVONuKe8YWTN',
			title: 'How to change the click sleeves and clean your CRE-E10 OTC Hearing Aids | hear.com',
			subtitle:
				'Walk through the steps to change and clean the soft ear tips of the Sony CRE-E10 OTC hearing aids.'
		},
		{
			id: '_zhViQW8nLE',
			url: 'https://www.youtube.com/embed/_zhViQW8nLE?si=G51TW5y8sKuSGHeX',
			title: 'How to insert, fit, and remove the Sony CRE-E10 OTC Hearing Aids | hear.com',
			subtitle:
				'This tutorial walks you through the steps to insert, fit, and remove the Sony CRE-E10 hearing aids.'
		},
		{
			id: 'hksjjVLUByA',
			url: 'https://www.youtube.com/embed/hksjjVLUByA?si=Zs0Ca_2ESUlX0yfa',
			title: 'How to turn the your CRE-E10 OTC Hearing Aids on and off | hear.com',
			subtitle:
				'Learn how to turn on and off your Sony CRE-E10 OTC hearing aids by following the steps shown in this video.'
		},
		{
			id: 'bSuU2iHTy7I',
			url: 'https://www.youtube.com/embed/bSuU2iHTy7I?si=5oUy-KL6wiTUEgww',
			title: 'Self-fitting Sony CRE-E10 Hearing Aids with iPhone | hear.com',
			subtitle:
				'Experience effortless fitting with Sony CRE-E10 hearing aids with iPhone for personalized comfort and superior sound.'
		},
		{
			id: 'HZ98iivm5zs',
			url: 'https://www.youtube.com/embed/HZ98iivm5zs?si=rFe4Po8iOgiuUMd4',
			title: 'How to connect Sony CRE-E10 Hearing Aids to iPhone Bluetooth | hear.com',
			subtitle: 'Connect your Sony CRE-E10 hearing aids to iPhone Bluetooth for instant, clear audio.'
		},
		{
			id: 'r5OMF39jjWU',
			url: 'https://www.youtube.com/embed/r5OMF39jjWU?si=K9O_9Z5MDsY2Uj96',
			title: 'Create Sony account for hearing aids with iPhone | hear.com',
			subtitle: 'Create a Sony account on your iPhone for easy management of your hearing aids.'
		},
		{
			id: '61uonLZOMyg',
			url: 'https://www.youtube.com/embed/61uonLZOMyg?si=1sC4UEH4YCThITgC',
			title: 'Download Sony Hearing Control App on iPhone | hear.com',
			subtitle: 'Quickly download the Sony Hearing Control App on your iPhone for seamless hearing aid management.'
		},
		{
			id: 'q2s19Mm23LA',
			url: 'https://www.youtube.com/embed/q2s19Mm23LA?si=xRnb3o2OriKox8aT',
			title: 'Self-fitting Sony CRE-E10 Hearing Aids with Android | hear.com',
			subtitle:
				'Experience effortless fitting with Sony CRE-E10 hearing aids with Android for personalized comfort and superior sound.'
		},
		{
			id: 'W94stD2iBYA',
			url: 'https://www.youtube.com/embed/W94stD2iBYA?si=6f0qXC7aJ1FWM3sx',
			title: 'How to connect Sony CRE-E10 Hearing Aids to Android Bluetooth | hear.com',
			subtitle: 'Connect your Sony CRE-E10 hearing aids to Android Bluetooth for instant, clear audio.'
		},
		{
			id: 'AegqFp0jaV0',
			url: 'https://www.youtube.com/embed/AegqFp0jaV0?si=aTAyVEGvCcrb9AhP',
			title: 'Create Sony account for hearing aids with Android | hear.com',
			subtitle: 'Create a Sony account on your Android for easy management of your hearing aids.'
		},
		{
			id: '-2iqZJSB-IA',
			url: 'https://www.youtube.com/embed/-2iqZJSB-IA?si=rOY3vrswP5TvP236',
			title: 'Download Sony Hearing Control App on Android | hear.com',
			subtitle: 'Quickly download the Sony Hearing Control App on your Android for seamless hearing aid management.'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default SonyCreETen;
